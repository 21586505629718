import React, {useState, useEffect } from "react"
import { useSpring, animated } from '@react-spring/web'

export default function Morph() {
  const [active, setActive] = useState(0);
  let { wiggle } = useSpring({ 
    config: { duration: 1200 },
    wiggle: active ? 1 : 0
  });
  useEffect(() => {
    const id = setTimeout(() => {
      setActive(!active);
    }, 1200);
    return () => clearTimeout(id);
  }, [active]);

  useEffect(() => {
    setActive(true);
  }, []);
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      width="300"
      height="300"
      version="1.1"
      viewBox="0 -20 350 375"
      // xmlSpace="preserve"
      >
      <animated.path
        d={wiggle.to({
          output: ["M.5,.5V361.5H361.5V.5H.5ZM181,330.58c-41.31,0-78.7-16.74-105.77-43.81-27.07-27.07-43.81-64.46-43.81-105.77,0-45.78,20.57-86.77,52.97-114.2,26.06-22.07,59.78-35.38,96.61-35.38,39.97,0,76.27,15.67,103.11,41.21,28.63,27.25,46.48,65.72,46.48,108.37,0,47.3-21.95,89.46-56.22,116.87-25.59,20.47-58.05,32.71-93.36,32.71Z", "M.5,.5V361.5H361.5V.5H.5ZM181,330.58c-41.31,0-64.37-29.64-91.44-56.71-27.07-27.07-58.14-51.57-58.14-92.87,0-45.78,20.57-86.77,52.97-114.2,26.06-22.07,59.78-15.06,96.61-15.06,39.97,0,76.27-4.64,103.11,20.9,28.63,27.25,33.17,65.72,33.17,108.37,0,47.3,1.9,98.95-32.37,126.36-25.59,20.47-68.59,23.22-103.91,23.22Z"],
        })}
      />
    </svg>
  );
}
