import React, {} from "react"
import Morph from "./circleMorph"
import styles from "../engestation.module.scss"


export default function CircleVideoMorph() {
  return (
    <div className={styles.morph}>
      <div className={styles.morphContainer}>
      <Morph />
      <video autoPlay loop muted playsInline>
        <source src="https://zj-v6.imgix.net/athena-content/I04-Exploring-Joy/I04-A02-Engestation/circle-hvec.mov" type='video/mp4; codecs="hvc1"' />
        <source src="https://zj-v6.imgix.net/athena-content/I04-Exploring-Joy/I04-A02-Engestation/circle.webm" type="video/webm" />
      </video>
      </div>
    </div>
  );
}
